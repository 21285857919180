"use strict";

/*global  jQuery*/
//jQuery(function ($) {
$(function () {
  //読み込みが完了
  $(window).on('load',function () { 
    console.log('jQuery Page Loaded.');  
  });
  
  /* iOS動作時の調整処理 */
  $(document).on("touchstart", ".menu-btn", function (event) {
    event.stopPropagation();
  });
  /* ハンバーガーメニューによる開閉処理 */
  $(document).on("click", ".menu-btn.close", function () {
    $(".sp-menu").addClass("open");
    $(".cover").addClass("open");
    $(".cover").removeClass("close");
    $(".sp-menu").removeClass("close");
    $(".menu-btn").addClass("open");
    $(".menu-btn").removeClass("close");
  });
  $(document).on("click", ".menu-btn.open,.cover.open", function () {
    $(".sp-menu").addClass("close");
    $(".sp-menu").removeClass("open");
    $(".cover").removeClass("open");
    $(".cover").addClass("close");
    $(".menu-btn").removeClass("open");
    $(".menu-btn").addClass("close");
  });
  
   //ページトップ戻るボタン 動作
  $(document).on("click", ".btn-child", function () {
    console.log('child-toggle');
    $(this).parent().children('.child-menu').slideToggle();
    $(this).toggleClass('open');
    
  });
  
     //ページトップ戻るボタン 動作
  $(document).on("click", ".btn-search", function () {
    console.log('SEARCH');
    $('.box-search').slideToggle();
  });
  $(document).on("click", ".box-search", function () {
    $('.box-search').slideToggle();
  });
  
    //ページトップ戻るボタン 表示ギミック
  $(window).scroll(function () {
    if ($(this).scrollTop() > 100) {
      $(".btn-pagetop").addClass('visible');
    } else {
      $(".btn-pagetop").removeClass('visible');
    }
  });
  
  //ページトップ戻るボタン 動作
  $(document).on("click", ".btn-pagetop", function () {
   console.log('DOM Content Loaded.');
    $('body,html').velocity("scroll", 500);
  });
  
  // スムーズスクロール 
  $(document).on('click', 'a[href*=\\#]:not([href=\\#])', function (event) {
    var href = $(this).attr("href"),
    target = $(href === "#" || href === "" ? 'html' : href);
    target.velocity("scroll", 500);
    return false;
  });
  
  $(document).ready(function(){
    $('.slide-top').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      dots: true,
      centerMode: true,
      autoplay: true,
      autoplaySpeed: 4000,
    });
    
    $('.slider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      asNavFor:'.thumb',
//      centerMode: true,
//      centerPadding: '100px',
      fade: true,
      autoplay: true,
      autoplaySpeed: 2500,
    });
    $('.thumb').slick({
      asNavFor:'.slider',
      focusOnSelect: true,
      slidesToShow:10,
      slidesToScroll:1
    });
  });
  
$('.btn-search').on('click',function(){
    $('.search-menu').slideDown();
  });
  $('.search-menu').on('click',function(){
    $(this).slideUp();
  });
  
  // On edge hit
  $('.slide-top').on('afterChange', function(event, slick, currentSlide, nextSlide){
    // console.log(currentSlide);
    switch (currentSlide){
      case 0:
      $('.slide01 .slide-content').addClass('animated fadeInLeft');  
      $('.slide01 .sub-image').addClass('animated fadeInLeft');
      $('.slide06 .slide-content').removeClass('animated fadeInLeft');
      $('.slide05 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide04 .slide-content').removeClass('animated fadeInUp');  
      $('.slide03 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide03 .sub-image').removeClass('animated fadeInLeft'); 
      $('.slide02 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide02 .sub-image').removeClass('animated fadeInLeft'); 
      break;

      case 1:
        $('.slide04 .slide-content').addClass('animated fadeInUp');
        $('.slide06 .slide-content').removeClass('animated fadeInLeft');
        $('.slide05 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide03 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide03 .sub-image').removeClass('animated fadeInLeft'); 
        $('.slide02 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide02 .sub-image').removeClass('animated fadeInLeft'); 
        $('.slide01 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide01 .sub-image').removeClass('animated fadeInLeft');
      break;

      case 2:
        $('.slide06 .slide-content').addClass('animated fadeInLeft');
        $('.slide05 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide04 .slide-content').removeClass('animated fadeInUp');  
        $('.slide03 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide02 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide02 .sub-image').removeClass('animated fadeInLeft'); 
        $('.slide01 .slide-content').removeClass('animated fadeInLeft');  
        $('.slide01 .sub-image').removeClass('animated fadeInLeft');  
      break;

      case 3:
      $('.slide02 .slide-content').addClass('animated fadeInLeft');
      $('.slide02 .sub-image').addClass('animated fadeInLeft'); 
      $('.slide06 .slide-content').removeClass('animated fadeInLeft');
      $('.slide05 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide04 .slide-content').removeClass('animated fadeInUp');  
      $('.slide03 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide03 .sub-image').removeClass('animated fadeInLeft'); 
      $('.slide01 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide01 .sub-image').removeClass('animated fadeInLeft');  
      break;

      case 4:
      $('.slide03 .slide-content').addClass('animated fadeInLeft');
      $('.slide03 .sub-image').addClass('animated fadeInLeft');  
      $('.slide06 .slide-content').removeClass('animated fadeInLeft');
      $('.slide05 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide04 .slide-content').removeClass('animated fadeInUp');  
      $('.slide02 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide02 .sub-image').removeClass('animated fadeInLeft'); 
      $('.slide01 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide01 .sub-image').removeClass('animated fadeInLeft');  
      break;

      case 5:
      $('.slide05 .slide-content').addClass('animated fadeInLeft');
      $('.slide06 .slide-content').removeClass('animated fadeInLeft');
      $('.slide04 .slide-content').removeClass('animated fadeInUp');  
      $('.slide03 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide03 .sub-image').removeClass('animated fadeInLeft'); 
      $('.slide02 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide02 .sub-image').removeClass('animated fadeInLeft'); 
      $('.slide01 .slide-content').removeClass('animated fadeInLeft');  
      $('.slide01 .sub-image').removeClass('animated fadeInLeft');   
      break;
    }
    
  });
  

  
  
});

//touchstartに対応してたらtouchstart、してなければclick
const myTap = window.ontouchstart===null?"touchstart":"click";

// DOM読み込み完了
document.addEventListener('DOMContentLoaded', function(e) {
//  console.log('DOM Content Loaded.');
}, false);

// 完全に読み込み完了
window.addEventListener('load', function() {
//  console.log('Page Loaded.');
});
